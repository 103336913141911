<template>
  <div>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Keresés</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Keresés"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <b-overlay :show="loading">
      <vue-good-table
        :columns="columns"
        :rows="allLogs"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
      >

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Jelenleg </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                találat a(z) {{ props.total }} -ból / ből
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BOverlay,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      columns: [
        {
          label: 'Dátum',
          field: 'created_at',
          type: 'string',
          filterOptions: {
            enabled: true,
            placeholder: 'Id keresés',
          },
        },
        {
          label: 'Termék neve',
          field: 'product.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Név keresés',
          },
        },
        {
          label: 'Mennyiség',
          field: 'qty',
          filterOptions: {
            enabled: true,
            placeholder: 'Mennyiség keresés',
          },
        },
        {
          label: 'Ár',
          field: 'price',
          filterOptions: {
            enabled: true,
            placeholder: 'Ár keresés',
          },
        },
        {
          label: 'Helyszín',
          field: 'warehouse.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Helyszín keresés',
          },
        },

        {
          label: 'Eladó',
          field: 'user.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Eladó keresés',
          },
        },
      ],
      loading: false,
      searchTerm: '',
    }
  },
  methods: {
    ...mapActions(['fetchRemoveLogs']),
    initRemoveLogs() {
      this.loading = true
      this.fetchRemoveLogs().then(res => {
        this.loading = false
      })
    },
  },
  computed: {
    ...mapGetters(['allLogs']),
  },
  created() {
    this.initRemoveLogs()
  },
}
</script>
